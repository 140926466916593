import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { dates } from '../constants'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Poděkování"
        description={`Poděkování po hře k šifrovací hře Navíc, která se konala v Praze ${dates.game}.`}
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Poděkování</h2>
                    </header>

					<p>Letošní šifrovačka Navíc navíc by nemohla proběhnout bez vydatné podpory našich kamarádů a blízkých, jakožto i bez materiálního zajištění a dobrých náhod. Těm poděkovat nemůžeme, ale vám, kteří jste nám s něčím pomohli, patří velké díky!</p>
					<p>Jsou to …</p>
					<p>… testeři šifer Jindřich Cinka, Iva Fadrná, Jarča Havířová, Kateřina a Tomáš Kloudovi, Jiří Kozel, Jana Pačísková, Lada Peksová, Verča a Martin Petrážovi a Ondřej Šmejkal, kteří s námi na blízko a nebo na dálku luštili starší verze šifer a pomohli nám z nich udělat to, co jste viděli na hře,</p>
					<p>… pomocnice na trase Paču a Vendy, které nám pomohly s roznosem stanovišť a také s dalšími činnostmi, které nejsou až tak vidět, ale bez nich by hra neběžela tak hladce,</p>
					<p>… dodavatelé materiálu:</p>
					<ul>
						<li>pro letošek nový partner pro tisk, kartografické vydavatelství a tiskárna ŽAKET,</li>
						<li>výrobce obalového materiálu Pack Shop Praha,</li>
						<li>online tiskárna Frances a </li>
						<li>tiskárna reklamních předmětů Potisk.com.</li>
					</ul>
					<p>… Městská knihovna v Praze za poskytnutí prostoru ve vitríně pro umístění kódu úkolu navíc j3,</p>
					<p>… a v neposlední řadě děkujeme kolektivu 4. přístavu vodních skautů Jana Nerudy za poskytnutí pronájmu jedinečných cílových prostor.</p>
					<p>Hra by ovšem nemohla proběhnout bez vás, hráčů, kteří jste do nás i letos vložili svou důvěru, že vám připravíme kvalitní šifrovací zábavu, a přišli za námi nejen na start, ale i do cíle, kde jste nám pomohli vytvořit příjemné prostředí u šifer a ohně (díky, hudebníci!) stejně jako loni a předloni.</p>
					<p>Aleš, Dero, Michal a Šárka</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
